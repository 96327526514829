// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-alfabetplaneten-js": () => import("./../../../src/pages/portfolio/alfabetplaneten.js" /* webpackChunkName: "component---src-pages-portfolio-alfabetplaneten-js" */),
  "component---src-pages-portfolio-alien-planets-2-js": () => import("./../../../src/pages/portfolio/alien-planets-2.js" /* webpackChunkName: "component---src-pages-portfolio-alien-planets-2-js" */),
  "component---src-pages-portfolio-alien-planets-js": () => import("./../../../src/pages/portfolio/alien-planets.js" /* webpackChunkName: "component---src-pages-portfolio-alien-planets-js" */),
  "component---src-pages-portfolio-creaza-js": () => import("./../../../src/pages/portfolio/creaza.js" /* webpackChunkName: "component---src-pages-portfolio-creaza-js" */),
  "component---src-pages-portfolio-crispin-glover-js": () => import("./../../../src/pages/portfolio/crispin-glover.js" /* webpackChunkName: "component---src-pages-portfolio-crispin-glover-js" */),
  "component---src-pages-portfolio-dodens-praktikant-js": () => import("./../../../src/pages/portfolio/dodens-praktikant.js" /* webpackChunkName: "component---src-pages-portfolio-dodens-praktikant-js" */),
  "component---src-pages-portfolio-filetskolen-js": () => import("./../../../src/pages/portfolio/filetskolen.js" /* webpackChunkName: "component---src-pages-portfolio-filetskolen-js" */),
  "component---src-pages-portfolio-film-og-kino-js": () => import("./../../../src/pages/portfolio/film-og-kino.js" /* webpackChunkName: "component---src-pages-portfolio-film-og-kino-js" */),
  "component---src-pages-portfolio-hogskolenvolda-js": () => import("./../../../src/pages/portfolio/hogskolenvolda.js" /* webpackChunkName: "component---src-pages-portfolio-hogskolenvolda-js" */),
  "component---src-pages-portfolio-ipr-js": () => import("./../../../src/pages/portfolio/IPR.js" /* webpackChunkName: "component---src-pages-portfolio-ipr-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-nrk-js": () => import("./../../../src/pages/portfolio/nrk.js" /* webpackChunkName: "component---src-pages-portfolio-nrk-js" */),
  "component---src-pages-portfolio-rotsethornet-js": () => import("./../../../src/pages/portfolio/rotsethornet.js" /* webpackChunkName: "component---src-pages-portfolio-rotsethornet-js" */),
  "component---src-pages-portfolio-sparebank-1-js": () => import("./../../../src/pages/portfolio/sparebank-1.js" /* webpackChunkName: "component---src-pages-portfolio-sparebank-1-js" */),
  "component---src-pages-portfolio-tristious-js": () => import("./../../../src/pages/portfolio/tristious.js" /* webpackChunkName: "component---src-pages-portfolio-tristious-js" */),
  "component---src-pages-portfolio-vicious-dogs-js": () => import("./../../../src/pages/portfolio/vicious-dogs.js" /* webpackChunkName: "component---src-pages-portfolio-vicious-dogs-js" */)
}

